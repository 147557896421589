// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-participant-index-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/participant/index.js" /* webpackChunkName: "component---src-pages-participant-index-js" */),
  "component---src-pages-resources-index-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-services-cobra-administration-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/cobra-administration.js" /* webpackChunkName: "component---src-pages-services-cobra-administration-js" */),
  "component---src-pages-services-cobra-faq-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/cobra-faq.js" /* webpackChunkName: "component---src-pages-services-cobra-faq-js" */),
  "component---src-pages-services-enrollment-benefit-admin-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/enrollment-benefit-admin.js" /* webpackChunkName: "component---src-pages-services-enrollment-benefit-admin-js" */),
  "component---src-pages-services-flexible-spending-accounts-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/flexible-spending-accounts.js" /* webpackChunkName: "component---src-pages-services-flexible-spending-accounts-js" */),
  "component---src-pages-services-health-reimbursement-arrangements-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/health-reimbursement-arrangements.js" /* webpackChunkName: "component---src-pages-services-health-reimbursement-arrangements-js" */),
  "component---src-pages-services-health-savings-accounts-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/health-savings-accounts.js" /* webpackChunkName: "component---src-pages-services-health-savings-accounts-js" */),
  "component---src-pages-services-on-leave-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/on-leave.js" /* webpackChunkName: "component---src-pages-services-on-leave-js" */),
  "component---src-pages-services-qsehra-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/qsehra.js" /* webpackChunkName: "component---src-pages-services-qsehra-js" */),
  "component---src-pages-services-retiree-billing-js": () => import("/Users/MME/Desktop/tristarsystems-master-2/src/pages/services/retiree-billing.js" /* webpackChunkName: "component---src-pages-services-retiree-billing-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/MME/Desktop/tristarsystems-master-2/.cache/data.json")

